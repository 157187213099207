import * as components from './';

const str_split = '/';

const isVueComponent = obj => !!(obj && (obj.template || obj.render))

const listObject = (obj, parentKey) => _(obj).map((value, key) => {
  const fullKey = parentKey ? [parentKey, key].join('.') : key;

  if(isVueComponent(value)) {
    const label = value.label || fullKey;
    const cascader_value = label.split(str_split).slice(0, -1).concat([fullKey])
    return { label, value: fullKey, cascader_value }
  }

  return listObject(value, fullKey)
}).flatten().value()

export default listObject(components)